<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.name"
    class="px-5 py-3"
    v-if="detail"
  >
    <base-registration-action-button
      :permissions="permissions"
      :basePath="basePath"
      :id="detail.id.toString()"
      v-if="detail.id && detail.status === 'PENDING'"
      @acceptAction="acceptAction"
      @rejectAction="rejectAction"
    >
    </base-registration-action-button>
    <v-row>
      <v-col cols="6" class="border--right">
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.name') }}
          </v-col>
          <v-col cols="6">
            {{ detail.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.phone') }}
          </v-col>
          <v-col cols="6">
            {{ detail.phone_number }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.email') }}
          </v-col>
          <v-col cols="6">
            {{ detail.email }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.agentType') }}
          </v-col>
          <v-col cols="6">
            {{ detail.agent_type }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.officeName') }}
          </v-col>
          <v-col cols="6">
            {{ detail.agent_office_name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.specialization') }}
          </v-col>
          <v-col cols="6">
            {{ detail.property_type_specializations }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.priceRange') }}
          </v-col>
          <v-col cols="6">
            {{ detail.property_price_specializations }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.city') }}
          </v-col>
          <v-col cols="6">
            {{ detail.cities }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.reference') }}
          </v-col>
          <v-col cols="6">
            {{ detail.reference }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.createdAt') }}
          </v-col>
          <v-col cols="6">
            {{ detail.created_at ? format(new Date(detail.created_at), 'dd MMMM yyyy HH:mm') : '' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.updatedAt') }}
          </v-col>
          <v-col cols="6">
            {{ detail.updated_at ? format(new Date(detail.updated_at), 'dd MMMM yyyy HH:mm') : '' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.status') }}
          </v-col>
          <v-col cols="6">
            {{ detail.status }}
          </v-col>
        </v-row>
        <!--        <v-row>-->
        <!--          <v-col cols="12">-->
        <!--            {{ $t('agentRegistration.ktp') }}-->
        <!--          </v-col>-->
        <!--          <v-col cols="12">-->
        <!--            <a-->
        <!--              v-if="detail.photos && detail.photos.data"-->
        <!--              :href="detail.photos.data.ktp_photo"-->
        <!--              target="_blank"-->
        <!--            >-->
        <!--              <img :src="detail.photos.data.ktp_photo" class="detail&#45;&#45;image" />-->
        <!--            </a>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <!--        <v-row>-->
        <!--          <v-col cols="12">-->
        <!--            {{ $t('agentRegistration.selfie') }}-->
        <!--          </v-col>-->
        <!--          <v-col cols="12">-->
        <!--            <a-->
        <!--              v-if="detail.photos && detail.photos.data"-->
        <!--              :href="detail.photos.data.selfie_photo"-->
        <!--              target="_blank"-->
        <!--            >-->
        <!--              <img :src="detail.photos.data.selfie_photo" class="detail&#45;&#45;image" />-->
        <!--            </a>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions'],
  computed: {
    ...mapState({
      basePath: (state) => state.agentRegistration.basePath,
    }),
  },
  data() {
    return {
      format,
    };
  },
  methods: {
    async acceptAction(id) {
      console.log('ACCEPT AGENT REGISTRATION ID: ', id);
      try {
        await this.$store.dispatch('agentRegistration/accept', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agentRegistration.accept.successMsg'),
          'success',
        );
        await this.$router.replace('/registration-management/agents');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async rejectAction(id) {
      console.log('REJECT AGENT REGISTRATION ID: ', id);
      try {
        await this.$store.dispatch('agentRegistration/reject', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agentRegistration.reject.successMsg'),
          'success',
        );
        await this.$router.replace('/registration-management/agents');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
